<template>
  <v-row justify="center">
    <v-col lg="10" xl="8">
      <v-card>
        <v-card-title>
          {{ $t("admin.charity.commitee.title", [committeeCode]) }}
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col>
                  <v-select v-model="month" :items="months" :rules="[$v.required()]" outlined></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-5 flex-column align-start">
          <v-card-subtitle> {{ $t("admin.charity.commitee.tax-recepts") }}</v-card-subtitle>
          <div class="d-flex">
            <v-btn raised small color="primary" v-on:click="exportDownload('VALIDATED')" :disabled="!valid || !has_validated_donations">
              <v-icon left>mdi-download </v-icon>
              {{ $t("admin.charity.commitee.list-xlsx") }}
            </v-btn>
            <v-btn
              :href="`${$store.state.settings.fundraisingBaseUrl}/download/taxreceipt/emitted/${$route.params.charityId}/${committeeCode}?month=${monthNumber}&year=${year}#`"
              target="_blank"
              class="ml-2"
              raised
              small
              color="primary"
              :disabled="!valid || !has_validated_donations"
            >
              <v-icon left>mdi-download </v-icon>
              {{ $t("admin.charity.commitee.tax-receipt-export") }}
            </v-btn>
          </div>
          <v-card-subtitle class="mt-8 d-flex">
            <span>{{ $t("admin.charity.commitee.cancelled-tax-recepts") }}</span>
            <v-divider />
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-2">mdi-information-outline</v-icon>
              </template>
              <span>{{ $t("admin.charity.commitee.cancelled-tax-recepts-info") }}</span>
            </v-tooltip>
          </v-card-subtitle>
          <v-divider />
          <v-btn raised small color="primary" v-on:click="exportDownload('CANCELLED_AFTER_VALIDATION')" :disabled="!valid || !has_cancelled_donations">
            <v-icon left>mdi-download </v-icon>
            {{ $t("admin.charity.commitee.list-xlsx") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
const fileDownload = require("js-file-download");
const moment = require("moment");

export default {
  data() {
    return {
      months: [],
      month: "",
      committes: [],
      committeeCode: this.$route.params.committeeCode,
      valid: false,
      has_validated_donations: false,
      has_cancelled_donations: false,
    };
  },
  async mounted() {
    this.month = moment().subtract(1, "months").format("MMMM YYYY");
    const first_donation = await this.$axios.get(
      `/charity/${this.$store.state.charity.id}/committe/${this.committeeCode}/donations/first`,
      {
        params: {
          month: this.month,
        },
    });
    this.generateMonthsFromDate(first_donation.creationDate);
  },
  methods: {
    async exportDownload(status) {
      this.$axios({
        url: `/charity/${this.$store.state.charity.id}/committe/${this.committeeCode}/donations/export`,
        method: "GET",
        responseType: "blob",
        params: {
          month: this.month,
          status,
        },
      }).then((response) => {
        fileDownload(
          response,
          `${this.$i18n.t("charity.commitee.donations.export")}_${this.committeeCode}_${
            moment(this.month, "MMMM YYYY").format("YY-MM")
          }.xls`
        );
      });
    },
    generateMonthsFromDate(startDateString) {
      let startDate = moment(startDateString, "YYYY-MM-DD");
      let currentDate = moment().subtract(1, "month");
      while (startDate.isSameOrBefore(currentDate, "month")) {
        this.months.push(startDate.format("MMMM YYYY"));
        startDate.add(1, "month");
      }
      this.months = this.months.reverse();
    },
  },
  watch: {
    async month() {
      const validated_donations = await this.$axios.get(
        `/charity/${this.$store.state.charity.id}/committe/${this.committeeCode}/donations`,
        {
          params: {
            month: this.month,
            status: "VALIDATED",
          },
        }
      );

      const cancelled_donations = await this.$axios.get(
        `/charity/${this.$store.state.charity.id}/committe/${this.committeeCode}/donations`,
        {
          params: {
            month: this.month,
            status: "CANCELLED",
          },
        }
      );

      this.has_validated_donations = !!validated_donations.length;
      this.has_cancelled_donations = !!cancelled_donations.length;
    },
  },
  computed: {
    monthNumber() {
      console.log(moment().month(this.month).format("M"))
      return moment().month(this.month).format("M");
    },
    year() {
      return moment(this.month).year();
    }
  }
};
</script>

<style>
.red-message-color .v-messages__message,
.red-input-color.theme--light.v-input input,
.red-label-color .v-label {
  color: #d91202;
  opacity: 1;
}
</style>