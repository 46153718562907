<template>
  <v-row justify="center">
    <v-col lg="10" xl="8">
      <v-card>
        <v-card-title>
          {{ $t("admin.charity.advanced.title") }}
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.charity.advanced.description") }}
        </v-card-subtitle>
        <v-card-text class="pb-0">
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="charity.donationThankYouMessage"
                    :rules="[$v.maxLength(1800)]"
                    :label="$t('admin.charity.advanced.thank_you_message')"
                    :hint="$t('admin.charity.advanced.thank_you_message_hint')"
                    persistent-hint
                    outlined
                    auto-grow
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>
          <v-btn raised color="primary" v-on:click="save" :disabled="!valid">{{
            $t("admin.charity.advanced.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      charity: {},
      valid: false,
    };
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      this.charity = await this.$axios.get(`/charity/${this.$store.state.charity.id}`);
    },
    async save() {
      await this.$axios.put(`/charity/${this.$store.state.charity.id}/thank-you`, this.charity);
      this.$store.commit("SUCCESS", this.$t("admin.charity.advanced.msg_saved"));
    },
  },
};
</script>

<style>
.red-message-color .v-messages__message,
.red-input-color.theme--light.v-input input,
.red-label-color .v-label {
  color: #d91202;
  opacity: 1;
}
</style>