<template>
  <div>
    <PageCard></PageCard>
    <v-list>
      <v-list-item :to="`/settings/charity/${$route.params.charityId}/profile`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-account-heart</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.charity.profile") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="`/settings/charity/${$route.params.charityId}/search`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-text-box-search-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.charity.search") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="`/settings/charity/${$route.params.charityId}/contact`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-cellphone-information</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.charity.contact") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="hasRole('ROLE_FUNDRAISING_ORGANIZATION_USERSUPPORT')"
        :to="`/settings/charity/${$route.params.charityId}/users`"
        ripple
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-group-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.charity.users") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="hasRole('ROLE_FUNDRAISING_ORGANIZATION_USERSUPPORT')"
        :to="`/settings/charity/${$route.params.charityId}/advanced`"
        ripple
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>mdi-cog-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.charity.advanced") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import PageCard from "../../_menu.context.selector.vue";

export default {
  data() {
    return {};
  },
  async mounted() {},
  components: {
    PageCard,
  },
  methods: {
    hasRole(role) {
      return (
        this.$store.state.me.userRoles.indexOf(role) !== -1 ||
        this.$store.state.me.userRoles.indexOf("ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING") !== -1
      );
    },
  },
};
</script>
