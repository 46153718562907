import Vue from "vue";
import Vuex from "vuex";
import axios from "./plugins/axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    messages: [],
    me: null,
    loading: false,
    charity: null,
  },
  mutations: {
    LOADING(state, loading) {
      state.loading = !!loading;
    },
    SUCCESS(state, message) {
      state.messages.push({
        type: "success",
        message: message,
        timeout: 4000,
      });
    },
    ERROR(state, message) {
      state.messages.push({
        type: "error",
        message: message,
      });
    },
    WARNING(state, message) {
      state.messages.push({
        type: "warning",
        message: message,
      });
    },
    INFO(state, message) {
      state.messages.push({
        type: "info",
        message: message,
        timeout: 6000,
      });
    },
    MESSAGE(state, [type, message, timeout]) {
      state.messages.push({
        type: type,
        message: message,
        timeout: timeout,
      });
    },
    I18N_MESSAGE(state, [type, key, params, timeout]) {
      state.messages.push({
        type: type,
        key: key,
        params: params,
        timeout: timeout,
      });
    },
    AUTH(state, auth) {
      state.auth = auth;
    },
    ME(state, me) {
      state.me = me;
    },
    CHARITY(state, charity) {
      state.charity = charity;
    },
  },
  actions: {
    async AUTHENTICATE({ commit, state }, payload) {
      let auth = payload.auth;
      let currentPath = payload.currentPath;
      /**
       * Load auth if not passed in parameters
       */
      if (typeof auth === "undefined") {
        if (state.auth) {
          auth = state.auth;
        } else if (!!window.localStorage && typeof localStorage.getItem === "function") {
          auth = JSON.parse("" + localStorage.getItem("auth"));
          auth =
            auth ||
            (payload.currentPath && payload.currentPath.query.auth
              ? {
                  access_token: payload.currentPath.query.auth,
                  expires_in: payload.currentPath.query.auth_exp ? parseInt(payload.currentPath.query.auth_exp) : null,
                  expires_date: null,
                }
              : null);
        }
      }
      /**
       * Calculate expires_date if not set
       */
      if (auth && !auth.expires_date) {
        auth.expires_date = new Date().getTime() + (auth.expires_in || 14400) * 1000;
      }
      /**
       * Check validity of auth token
       */
      if (!auth || auth.expires_date < new Date().getTime() + 60000) {
        commit("AUTH", null);
        commit("ME", null);
        axios.defaults.headers.common = null;
        if (!!window.localStorage && typeof localStorage.removeItem === "function") {
          localStorage.removeItem("auth");
        }
        return null;
      }
      /**
       * Don't load me from server if already loaded and auth still valid
       */
      if (state.auth === auth && state.me) {
        return state.me;
      }
      /**
       * Load me from server
       */
      commit("AUTH", auth);
      axios.defaults.headers.common = {
        Authorization: `Bearer ${auth.access_token}`,
      };
      if (!!window.localStorage && typeof localStorage.setItem === "function") {
        localStorage.setItem("auth", JSON.stringify(auth));
      }

      let url = currentPath && currentPath.params.charityId ? "/user/me/" + currentPath.params.charityId : "/user/me";
      if (currentPath.params.committeeCode) {
        url += `/${currentPath.params.committeeCode}`;
      }
      const result = await axios.get(url);
      commit("ME", {
        id: result.id,
        login: result.login,
        userName: result.userName,
        userRoles: result.userRoles,
      });
      commit("CHARITY", {
        billingId: result.charityBillingId,
        id: result.charityId,
        logoUrl: result.charityLogoUrl,
        name: result.charityName,
        creationDate: result.charityCreationDate,
        portalUrl: result.charityPortalUrl,
      });

      return state.me;
    },
  },
});
