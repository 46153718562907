import Vue from "vue";
import Router from "vue-router";
import NotFound from "./pages/_notFound";
import store from "./store";

const PassThrough = {
  render(c) {
    return c("router-view");
  },
};

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: require("./pages/admin/_layout.admin").default,
      redirect: "my/applications",
      children: [
        {
          path: "my/applications",
          components: {
            default: require("./pages/admin/app/appSelector/landingPage").default,
          },
        },
        {
          path: "my/:charityId/applications",
          components: {
            default: require("./pages/admin/app/appSelector/landingPage").default,
          },
        },
      ],
    },
    {
      path: "/admin/",
      component: PassThrough,
      beforeEnter(to, from, next) {
        if (store.state.me) {
          return next();
        }
        return next(`/`);
      },
      children: [
        {
          path: "",
          redirect: "charity",
        },
        {
          path: "charity/:charityId",
          component: PassThrough,
          children: [
            {
              path: "committee",
              component: require("./pages/admin/_layout.admin").default,
              children: [
                {
                  path: ":committeeCode",
                  components: {
                    menu: require("./pages/admin/app/personalFundraising/_menu.app").default,
                    default: require("./pages/admin/app/charity/committee").default,
                  },
                },
              ],
            },

            {
              path: "",
              redirect: "events",
            },
            {
              path: "events",
              component: require("./pages/admin/_layout.admin").default,
              children: [
                {
                  path: "",
                  components: {
                    menu: require("./pages/admin/_menu.context.selector").default,
                    default: require("./pages/admin/app/eventFundraising/events.list").default,
                  },
                },
                {
                  path: ":eventId/dashboard",
                  components: {
                    menu: require("./pages/admin/app/eventFundraising/_menu.app").default,
                    default: require("./pages/admin/app/eventFundraising/dashboard").default,
                  },
                },
                {
                  path: ":eventId/fundraisers",
                  components: {
                    menu: require("./pages/admin/app/eventFundraising/_menu.app").default,
                    default: require("./pages/admin/app/eventFundraising/fundraisers").default,
                  },
                },
                {
                  path: ":eventId/groups",
                  components: {
                    menu: require("./pages/admin/app/eventFundraising/_menu.app").default,
                    default: require("./pages/admin/app/eventFundraising/groups").default,
                  },
                },
                {
                  path: ":eventId/branding",
                  components: {
                    menu: require("./pages/admin/app/eventFundraising/_menu.app").default,
                    default: require("./pages/admin/app/eventFundraising/branding").default,
                  },
                },
                {
                  path: ":eventId/settings/eventProfile",
                  components: {
                    menu: require("./pages/admin/app/eventFundraising/_menu.app").default,
                    default: require("./pages/admin/app/eventFundraising/settings/eventProfile").default,
                  },
                },
                {
                  path: ":eventId/settings/campaignPage",
                  components: {
                    menu: require("./pages/admin/app/eventFundraising/_menu.app").default,
                    default: require("./pages/admin/app/eventFundraising/settings/campaignPage").default,
                  },
                },
                {
                  path: ":eventId/settings/fundraising",
                  components: {
                    menu: require("./pages/admin/app/eventFundraising/_menu.app").default,
                    default: require("./pages/admin/app/eventFundraising/settings/fundraising").default,
                  },
                },
                {
                  path: ":eventId/settings/coaching",
                  components: {
                    menu: require("./pages/admin/app/eventFundraising/_menu.app").default,
                    default: require("./pages/admin/app/eventFundraising/settings/coachingEmails").default,
                  },
                },
                {
                  path: ":eventId/settings/notifications",
                  components: {
                    menu: require("./pages/admin/app/eventFundraising/_menu.app").default,
                    default: require("./pages/admin/app/eventFundraising/settings/notifications").default,
                  },
                },
              ],
            },
            {
              path: "*",
              component: NotFound,
            },
          ],
        },
        {
          path: "/fr/*",
          redirect: "/",
        },
        {
          path: "/en/*",
          redirect: "/",
        },
        {
          path: "*",
          component: NotFound,
        },
      ],
    },
    {
      path: "/donationForm/",
      component: PassThrough,
      beforeEnter(to, from, next) {
        if (store.state.me) {
          return next();
        }
        return next(`/`);
      },
      meta: {
        roles: ["ROLE_FUNDRAISING_ORGANIZATION_DONATIONFORMS"],
      },
      children: [
        {
          path: "",
          redirect: "charity",
        },
        {
          path: "charity/:charityId",
          component: PassThrough,
          children: [
            {
              path: "",
              redirect: "forms",
            },
            {
              path: "forms",
              component: require("./pages/admin/_layout.admin").default,
              children: [
                {
                  path: "",
                  components: {
                    menu: require("./pages/admin/_menu.context.selector").default,
                    default: require("./pages/admin/app/donationForm/forms.list").default,
                  },
                },
                {
                  path: ":formId/dashboard",
                  components: {
                    menu: require("./pages/admin/app/donationForm/_menu.app").default,
                    default: require("./pages/admin/app/donationForm/dashboard").default,
                  },
                },
                {
                  path: ":formId/branding",
                  components: {
                    menu: require("./pages/admin/app/donationForm/_menu.app").default,
                    default: require("./pages/admin/app/donationForm/branding").default,
                  },
                },
                {
                  path: ":formId/settings",
                  components: {
                    menu: require("./pages/admin/app/donationForm/_menu.app").default,
                    default: require("./pages/admin/app/donationForm/settings/settings").default,
                  },
                },
                {
                  path: ":formId/links",
                  components: {
                    menu: require("./pages/admin/app/donationForm/_menu.app").default,
                    default: require("./pages/admin/app/donationForm/settings/links").default,
                  },
                },
                {
                  path: ":formId/notifications",
                  components: {
                    menu: require("./pages/admin/app/donationForm/_menu.app").default,
                    default: require("./pages/admin/app/donationForm/settings/notifications").default,
                  },
                },
                {
                  path: ":formId/donors",
                  components: {
                    menu: require("./pages/admin/app/donationForm/_menu.app").default,
                    default: require("./pages/admin/app/donationForm/donors").default,
                  },
                },
              ],
            },
            {
              path: "*",
              component: NotFound,
            },
          ],
        },
        {
          path: "/fr/*",
          redirect: "/",
        },
        {
          path: "/en/*",
          redirect: "/",
        },
        {
          path: "*",
          component: NotFound,
        },
      ],
    },
    {
      path: "/personal/",
      component: PassThrough,
      beforeEnter(to, from, next) {
        if (store.state.me) {
          return next();
        }
        return next(`/`);
      },
      meta: {
        roles: ["ROLE_FUNDRAISING_ORGANIZATION_FUNDRAISING"],
      },
      children: [
        {
          path: "",
          redirect: "charity",
        },
        {
          path: "charity/:charityId",
          component: require("./pages/admin/_layout.admin").default,
          children: [
            {
              path: "",
              redirect: "dashboard",
            },
            {
              path: "dashboard",
              components: {
                menu: require("./pages/admin/app/personalFundraising/_menu.app").default,
                default: require("./pages/admin/app/personalFundraising/dashboard").default,
              },
            },
            {
              path: "fundraisers",
              components: {
                menu: require("./pages/admin/app/personalFundraising/_menu.app").default,
                default: require("./pages/admin/app/personalFundraising/fundraisers").default,
              },
            },
            {
              path: "branding",
              components: {
                menu: require("./pages/admin/app/personalFundraising/_menu.app").default,
                default: require("./pages/admin/app/personalFundraising/branding").default,
              },
            },
            {
              path: "settings/content",
              components: {
                menu: require("./pages/admin/app/personalFundraising/_menu.app").default,
                default: require("./pages/admin/app/personalFundraising/settings/content").default,
              },
            },
            {
              path: "settings/challenges",
              components: {
                menu: require("./pages/admin/app/personalFundraising/_menu.app").default,
                default: require("./pages/admin/app/personalFundraising/settings/challenges").default,
              },
            },
            {
              path: "settings/coaching",
              components: {
                menu: require("./pages/admin/app/personalFundraising/_menu.app").default,
                default: require("./pages/admin/app/personalFundraising/settings/coachingEmails").default,
              },
            },
            {
              path: "settings/notifications",
              components: {
                menu: require("./pages/admin/app/personalFundraising/_menu.app").default,
                default: require("./pages/admin/app/personalFundraising/settings/notifications").default,
              },
            },
            {
              path: "inmemoriam",
              components: {
                menu: require("./pages/admin/app/personalFundraising/_menu.app").default,
                default: require("./pages/admin/app/personalFundraising/inMemoriam").default,
              },
            },
          ],
        },
        {
          path: "*",
          component: NotFound,
        },
      ],
    },

    {
      path: "/settings/",
      component: PassThrough,
      beforeEnter(to, from, next) {
        if (store.state.me) {
          return next();
        }
        return next(`/`);
      },
      children: [
        {
          path: "",
          redirect: "charity",
        },
        {
          path: "charity/:charityId",
          component: require("./pages/admin/_layout.admin").default,
          children: [
            {
              path: "",
              redirect: "profile",
            },
            {
              path: "profile",
              components: {
                menu: require("./pages/admin/app/charity/_menu.app").default,
                default: require("./pages/admin/app/charity/profile").default,
              },
            },
            {
              path: "search",
              components: {
                menu: require("./pages/admin/app/charity/_menu.app").default,
                default: require("./pages/admin/app/charity/search").default,
              },
            },
            {
              path: "contact",
              components: {
                menu: require("./pages/admin/app/charity/_menu.app").default,
                default: require("./pages/admin/app/charity/contact").default,
              },
            },
            {
              path: "users",
              components: {
                menu: require("./pages/admin/app/charity/_menu.app").default,
                default: require("./pages/admin/app/charity/users").default,
              },
              meta: {
                roles: ["ROLE_FUNDRAISING_ORGANIZATION_USERSUPPORT"],
              },
            },
            {
              path: "advanced",
              components: {
                menu: require("./pages/admin/app/charity/_menu.app").default,
                default: require("./pages/admin/app/charity/advanced").default,
              },
            },
          ],
        },
        {
          path: "*",
          component: NotFound,
        },
      ],
    },
    {
      path: "/marketing/",
      component: PassThrough,
      beforeEnter(to, from, next) {
        if (store.state.me) {
          return next();
        }
        return next(`/`);
      },
      meta: {
        roles: ["ROLE_FUNDRAISING_ORGANIZATION_MARKETING"],
      },
      children: [
        {
          path: "",
          redirect: "charity",
        },
        {
          path: "charity/:charityId",
          component: require("./pages/admin/_layout.admin").default,
          children: [
            {
              path: "",
              redirect: "dashboard",
            },
            {
              path: "dashboard",
              components: {
                menu: require("./pages/admin/app/marketingExports/_menu.app").default,
                default: require("./pages/admin/app/marketingExports/dashboard").default,
              },
            },
            {
              path: "fundraisers",
              components: {
                menu: require("./pages/admin/app/marketingExports/_menu.app").default,
                default: require("./pages/admin/app/marketingExports/fundraisers").default,
              },
            },
            {
              path: "donors",
              components: {
                menu: require("./pages/admin/app/marketingExports/_menu.app").default,
                default: require("./pages/admin/app/marketingExports/donors").default,
              },
            },
            {
              path: "custom-export",
              components: {
                menu: require("./pages/admin/app/marketingExports/_menu.app").default,
                default: require("./pages/admin/app/marketingExports/customExport").default,
              },
            },
          ],
        },
        {
          path: "*",
          component: NotFound,
        },
      ],
    },
    {
      path: "/superadmin/",
      component: PassThrough,
      beforeEnter(to, from, next) {
        if (store.state.me) {
          return next();
        }
        return next(`/`);
      },
      meta: {
        roles: ["ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING"],
      },
      children: [
        {
          path: "",
          component: require("./pages/admin/_layout.admin").default,
          children: [
            {
              path: "",
              redirect: "dashboard",
            },
            {
              path: "dashboard",
              components: {
                menu: require("./pages/admin/app/superadmin/_menu.app").default,
                default: require("./pages/admin/app/superadmin/dashboard").default,
              },
              meta: {
                roles: ["ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING"],
              },
            },
            {
              path: "transactions",
              components: {
                menu: require("./pages/admin/app/superadmin/_menu.app").default,
                default: require("./pages/admin/app/superadmin/transactions").default,
              },
              meta: {
                roles: ["ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING"],
              },
            },
            {
              path: "users",
              components: {
                menu: require("./pages/admin/app/superadmin/_menu.app").default,
                default: require("./pages/admin/app/superadmin/users").default,
              },
              meta: {
                roles: ["ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING"],
              },
            },
            {
              path: "fundraisingPages",
              components: {
                menu: require("./pages/admin/app/superadmin/_menu.app").default,
                default: require("./pages/admin/app/superadmin/fundraisingPages").default,
              },
              meta: {
                roles: ["ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING"],
              },
            },
            {
              path: "groupsList",
              components: {
                menu: require("./pages/admin/app/superadmin/_menu.app").default,
                default: require("./pages/admin/app/superadmin/groupsList").default,
              },
              meta: {
                roles: ["ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING"],
              },
            },
            {
              path: "events",
              components: {
                menu: require("./pages/admin/app/superadmin/_menu.app").default,
                default: require("./pages/admin/app/superadmin/events").default,
              },
              meta: {
                roles: ["ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING"],
              },
            },
            {
              path: "charities",
              components: {
                menu: require("./pages/admin/app/superadmin/_menu.app").default,
                default: require("./pages/admin/app/superadmin/charities").default,
              },
              meta: {
                roles: ["ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING"],
              },
            },
            {
              path: "superadmins",
              components: {
                menu: require("./pages/admin/app/superadmin/_menu.app").default,
                default: require("./pages/admin/app/superadmin/superadmins").default,
              },
              meta: {
                roles: ["ROLE_FUNDRAISING_APPLICATIONOWNER_ADMIN"],
              },
            },
          ],
        },
        {
          path: "*",
          component: NotFound,
        },
      ],
    },
    {
      path: "/*",
      component: NotFound,
      meta: {
        public: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  store.dispatch("AUTHENTICATE", { currentPath: to }).then(() => {
    if (isPublicPath(to)) {
      return next();
    }
    if (store.state.me) {
      if (to.query.auth) {
        let query = Object.assign({}, to.query);
        delete query.auth;
        delete query.auth_exp;
        router.replace({ path: to.path, query: query });
      }
      let roles = getRequiredRoles(to);
      for (let n in roles) {
        if (!hasRole(store.state.me, roles[n])) {
          console.log("NO ACCESS");
          return false;
        }
      }
      if (to.meta.roles) {
        console.log(to.meta.roles);
        console.log(to.user);
      }
      next();
    } else {
      window.location.replace(
        `${store.state.settings.casBaseUrl}/login?service=${encodeURIComponent(
          store.state.settings.casServiceUrl
        )}?backUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`
      );
    }
  });
});
const isPublicPath = (to) => {
  for (let i = to.matched.length - 1; i >= 0; i--) {
    if (to.matched[i].meta.public !== undefined) {
      return to.matched[i].meta.public;
    }
  }
  return null;
};
const getRequiredRoles = (to) => {
  var roles = [];
  for (let i = to.matched.length - 1; i >= 0; i--) {
    if (to.matched[i].meta.roles !== undefined) {
      roles = roles.concat(to.matched[i].meta.roles);
    }
  }
  return roles;
};
const hasRole = (user, role) => {
  return user.userRoles.indexOf(role) !== -1 || user.userRoles.indexOf("ROLE_ALVARUM_PRODUCTS_ADMINISTRATOR") !== -1;
};
export default router;
